import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../../global.css';
import { Container, Title, Content, Icon, IconWrapper } from './index.css';

const query = graphql`
  query WhoWeAreFeaturesQuery {
    whoWeAreJson {
      features {
        title
        items {
          title
          image {
            publicURL
          }
        }
      }
    }
  }
`;

const WhoWeAreFeatures = () => {
  const data = useStaticQuery(query).whoWeAreJson.features;

  return (
    <ContentWrapper padding={30}>
      <Container>
        <Title>{data.title}</Title>
        <Content>
          {data.items.map((item, index) => (
            <IconWrapper key={index}>
              <Icon src={item.image.publicURL} alt={item.title} />
            </IconWrapper>
          ))}
        </Content>
      </Container>
    </ContentWrapper>
  );
};

export default WhoWeAreFeatures;
