import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 100px;
  padding-bottom: 122px;
  align-items: center;
  background: ${color.white};

  ${MEDIA.MIN_TABLET`
    padding-bottom: 100px;
  `};
`;

export const Content = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: auto auto;

  ${MEDIA.MIN_TABLET`
    grid-template-columns: auto auto auto;
    grid-auto-rows: 1fr;
  `};
`;

export const Title = styled.h2`
  font-family: ${font.secondary};
  font-size: 40px;
  line-height: 45px;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 30px;

  text-align: center;

  ${MEDIA.MIN_TABLET`
    font-size: 60px;
    line-height: 60px;
  `};
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  display: block;
`;
