import styled from 'styled-components';
import { color } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 100px;
  padding-bottom: 122px;
  text-align: left;
  background: ${color.secondaryBg};

  ${MEDIA.MIN_TABLET`
    padding-bottom: 100px;
  `};
`;

export const Content = styled.div`
  display: grid;
  grid-gap: 100px;

  ${MEDIA.MIN_TABLET`
    grid-template-columns: 1fr 1fr;
  `};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 24px
  font-weight: 400;
  max-width: 492px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 74px;
`;

export const Partner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled.img``;

export const Text = styled.p`
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
  line-height: 24px;
`;

export const Link = styled.a`
  color: ${color.pink};
`;
