import React from 'react';
import Layout from 'components/layout';
import Hero from 'components/whoWeAre/hero';
import Features from 'components/whoWeAre/features';
import Partners from 'components/whoWeAre/partners';
import StoreMinimalCtaSection from 'components/storeCtaMinimalSection';

const WhoWeAre = () => (
  <Layout>
    <Hero />
    <Features />
    <Partners />
    <StoreMinimalCtaSection />
  </Layout>
);

export default WhoWeAre;
