import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../../global.css';
import {
  Container,
  Title,
  TitleWrapper,
  Subtitle,
  Content,
  Icon,
  Partner,
  Text,
  Link,
} from './index.css';

const query = graphql`
  query WhoWeArePartnersQuery {
    whoWeAreJson {
      partners {
        image {
          src { 
            publicURL 
          }
          title
        }
        subtitle
        items {
          image {
            publicURL
          }
          partner
          text
          url
        }
      }
    }
  }
`;

const WhoWeAreFeatures = () => {
  const data = useStaticQuery(query).whoWeAreJson.partners;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <TitleWrapper>
          <Title src={data.image.src.publicURL} alt={data.image.title} />
          <Subtitle>{data.subtitle}</Subtitle>
        </TitleWrapper>
        <Content>
          {data.items.map((item, index) => (
            <Partner key={index}>
              <Icon src={item.image.publicURL} alt={item.partner} />
              <Text>
                {item.text}{' '}
                <Link
                  href={item.url}
                  aria-label={item.partner}
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </Link>
              </Text>
            </Partner>
          ))}
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default WhoWeAreFeatures;
